import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import ApiConfig from '../../api_config.json';

@Injectable({
  providedIn: 'root'
})
export class DynamicsService {
  constructor(private http: HttpClient) { }

  getIsLive(): Observable<string> {
    return this.http.get<string>(`${ApiConfig.apiBaseUrl}/api/Dynamics/is-live`);
  }

  getAccessToken(): Observable<string> {
    return this.http.get<string>(`${ApiConfig.apiBaseUrl}/api/Dynamics/access-token`);
  }

  saveNewOrder(data: any): Observable<any> {
    return this.http.post<any>(`${ApiConfig.apiBaseUrl}/api/Dynamics/new-order`, data);
  }

  saveBillMeDetails(data: any): Observable<any> {
    return this.http.post<any>(`${ApiConfig.apiBaseUrl}/api/Dynamics/bill-me`, data);
  }
}