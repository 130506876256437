<div class="checkout mt-5">
    <div *ngIf="error" class="container">
        <app-errormessage
            [serverErrorMessage]="error.message"
            [errorMessageTranslationKey]="error.localizationKey"
        >
        </app-errormessage>
    </div>

    <div class="card col-md-10 offset-md-1 col-xl-8 offset-xl-2">
        <div class="row">
            <div class="card-header">
                <h2 class="card-title">
                    <span [appTranslate]="'DemoPaymentGatewayPageTitle'"
                        >Bill Me</span
                    >
                </h2>
            </div>
            <div class="card-body">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 p-2">
                            <div class="row">
                                <div class="col-12">
                                    <h3
                                        class="h4"
                                        [appTranslate]="'OrderSummary'"
                                    >
                                        Order Summary
                                    </h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <b [appTranslate]="'Description'"
                                        >Description:</b
                                    >
                                    <br />
                                    Event pass
                                </div>
                                <div class="col-6">
                                    <b [appTranslate]="'Amount'">Amount:</b>
                                    <br />
                                    {{ currencySymbol }}{{ (total * 1.05) |
                                    number:'1.2-2'}}
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6 p-2">
                            <div class="row">
                                <div class="col-12">
                                    <h3
                                        class="h4"
                                        [appTranslate]="'BillingAddressDetails'"
                                    >
                                        Billing Address Details
                                    </h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 mt-2">
                                    <label for="firstName">
                                        <b [appTranslate]="'FirstName'"
                                            >First Name
                                            <span class="required-asterisk"
                                                >*</span
                                            ></b
                                        >
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="First Name"
                                        style="width: 100%"
                                        required
                                    />
                                </div>
                                <div class="col-6 mt-2">
                                    <label for="lastName">
                                        <b [appTranslate]="'LastName'"
                                            >Last Name
                                            <span class="required-asterisk"
                                                >*</span
                                            ></b
                                        >
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Last Name"
                                        style="width: 100%"
                                        required
                                    />
                                </div>
                                <div class="col-12 mt-2">
                                    <label for="address1">
                                        <b [appTranslate]="'Address1'"
                                            >Address Line 1
                                            <span class="required-asterisk"
                                                >*</span
                                            ></b
                                        >
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="address1"
                                        name="address1"
                                        placeholder="Address Line 1"
                                        style="width: 100%"
                                        required
                                    />
                                </div>
                                <div class="col-12 mt-2">
                                    <label for="address2">
                                        <b [appTranslate]="'Address2'"
                                            >Address Line 2</b
                                        >
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="address2"
                                        name="address2"
                                        placeholder="Address Line 2"
                                        style="width: 100%"
                                    />
                                </div>
                                <div class="col-6 mt-2">
                                    <label for="city">
                                        <b [appTranslate]="'City'"
                                            >City
                                            <span class="required-asterisk"
                                                >*</span
                                            ></b
                                        >
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="city"
                                        name="city"
                                        placeholder="City"
                                        style="width: 100%"
                                        required
                                    />
                                </div>
                                <div class="col-6 mt-2">
                                    <label for="province">
                                        <b [appTranslate]="'Province'"
                                            >Province
                                            <span class="required-asterisk"
                                                >*</span
                                            ></b
                                        >
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="province"
                                        name="province"
                                        placeholder="Province"
                                        style="width: 100%"
                                        required
                                    />
                                </div>
                                <div class="col-6 mt-2">
                                    <label for="postal">
                                        <b [appTranslate]="'Postal'"
                                            >Postal
                                            <span class="required-asterisk"
                                                >*</span
                                            ></b
                                        >
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="postal"
                                        name="postal"
                                        placeholder="Postal"
                                        style="width: 100%"
                                        required
                                    />
                                </div>
                                <div class="col-6 mt-2">
                                    <label for="phone">
                                        <b [appTranslate]="'Phone'"
                                            >Phone
                                            <span class="required-asterisk"
                                                >*</span
                                            ></b
                                        >
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        placeholder="Phone"
                                        style="width: 100%"
                                        required
                                    />
                                </div>
                                <div class="col-6 mt-2">
                                    <label for="email">
                                        <b [appTranslate]="'Email'"
                                            >Email
                                            <span class="required-asterisk"
                                                >*</span
                                            ></b
                                        >
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        placeholder="Email"
                                        style="width: 100%"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <div class="payment-actions">
                    <div>
                        <button
                            (click)="cancel()"
                            class="btn btn-secondary btn-lg"
                        >
                            <span [appTranslate]="'CancelPayment'">Cancel</span>
                        </button>
                    </div>
                    <div>
                        <button
                            (click)="finalizeBillMe()"
                            class="btn btn-primary btn-lg makePayment"
                        >
                            <span
                                *ngIf="!registrationInProgress"
                                [appTranslate]="'EmulatePayment'"
                            >
                                <span class="fa fa-file-text"></span>
                                Bill Now
                            </span>
                            <span
                                *ngIf="registrationInProgress"
                                [appTranslate]="'Processing'"
                            >
                                <span class="fa fa-spinner fa-spin"></span>
                                Processing
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
