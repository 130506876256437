import { HipObject } from './HipObject';

export interface PaymentDetails {
    cardNumber: string;
    cardHolder: string;
    expiryDate: string;
    cvv: string;
}

export interface FinalizeRegistrationRequest {
    purchaseId: string;
    hipObject: HipObject;
    paymentDetails: PaymentDetails; // Include PaymentDetails here
}
