import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { EVENT_SERVICE } from '../../../providers/service.providers';
import { EventService } from 'src/app/services/event.service';
import { BrowserSessionService } from '../../../services/browser-session.service';
import { TranslationKeys } from 'src/app/constants/TranslationKeys';
import { LocalizableError } from 'src/app/models/LocalizableError';
import { environment } from 'src/environments/environment.prod';
import {
    FinalizeRegistrationRequest,
    PaymentDetails,
} from '../../../models/FinalizeRegistrationRequest';
import { Attendee } from 'src/app/models/Attendee';
import { Pass } from 'src/app/models/Pass';
import { PassService } from 'src/app/services/pass.service';
import { DynamicsService } from 'src/app/services/dynamics.service';

@Component({
    selector: 'app-bill-me',
    templateUrl: './bill-me.html',
    styleUrls: ['./bill-me.scss'],
})
export class BillMeComponent implements OnInit {
    public error: LocalizableError;
    public total: number;
    public currencySymbol: string;
    public registrationInProgress: boolean;
    private readableEventId: string;
    private purchaseId: string;
    private accessToken: string;
    private userRole: string[];
    private attendees: Attendee[];
    private passes: Pass[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        @Inject(EVENT_SERVICE) private eventService: EventService,
        private browserSessionService: BrowserSessionService,
        private passService: PassService,
        private dynamicsService: DynamicsService,
        private http: HttpClient
    ) {}

    ngOnInit() {
        this.total = this.browserSessionService.getRegistrationTotal();

        this.currencySymbol = this.route.snapshot.queryParams['currencySymbol'];
        this.purchaseId = this.route.snapshot.queryParams['purchaseId'];
        this.readableEventId = this.route.snapshot.queryParams['id'];
        this.userRole = this.route.snapshot.queryParams['userRole'];

        this.attendees = JSON.parse(
            decodeURIComponent(this.route.snapshot.queryParams['attendees'])
        );
        this.passes = JSON.parse(
            decodeURIComponent(this.route.snapshot.queryParams['passes'])
        );

        this.addIndividualPassPrice();
    }

    private addIndividualPassPrice() {
        this.attendees.forEach((attendee) => {
            const pass = this.passes.find(
                (p) => p?.passId === attendee?.passId
            );
            if (pass) {
                attendee.userRole = this.userRole;
                attendee.individualPassPrice =
                    this.passService.getLowestPassPrice(pass, this.userRole);
            }
        });
    }

    cancel(): void {
        this.router.navigate(['/event', this.readableEventId]);
    }

    // Temporary solution. @TODO: Delete after implementing a backend.

    private isEmpty(field: string): boolean {
        return !field || field.trim() === '';
    }

    finalizeBillMe(): void {
        this.registrationInProgress = true;

        
        const firstName = (
            document.getElementById('firstName') as HTMLInputElement
        ).value;
        const lastName = (
            document.getElementById('lastName') as HTMLInputElement
        ).value;
        const address = (
            document.getElementById('address1') as HTMLInputElement
        ).value;
        const address2 = (
            document.getElementById('address2') as HTMLInputElement
        ).value;
        const city = (document.getElementById('city') as HTMLInputElement)
            .value;
        const province = (
            document.getElementById('province') as HTMLInputElement
        ).value;
        const postal = (document.getElementById('postal') as HTMLInputElement)
            .value;
        const phone = (document.getElementById('phone') as HTMLInputElement)
            .value;
        const email = (document.getElementById('email') as HTMLInputElement)
            .value;

        // Temporary handler for validation @TODO: Improve validation
        const requiredFields = [
            { field: address, name: 'Address' },
            { field: city, name: 'City' },
            { field: province, name: 'Province' },
            { field: postal, name: 'Postal Code' },
            { field: phone, name: 'Phone' },
            { field: email, name: 'Email' },
        ];

        for (const { field } of requiredFields) {
            if (this.isEmpty(field)) {
                alert(`Please fill in required fields`);
                this.registrationInProgress = false;
                return; // Prevent form submission or further processing
            }
        }

        const requestData: FinalizeRegistrationRequest = {
            purchaseId: this.purchaseId,
            hipObject: this.browserSessionService.getCaptcha(),
            paymentDetails: null,
        };


        this.eventService
            .finalizeRegistration(this.readableEventId, requestData)
            .subscribe(
                (registrationResult) => {
                    if (registrationResult.status === 'Success') {
                        const data = {
                            crd31_fullname: `${firstName} ${lastName}`,
                            crd31_firstName: firstName,
                            crd31_lastName: lastName,
                            crd31_email: email,
                            crd31_address1: address,
                            crd31_address2: address2,
                            crd31_city: city,
                            crd31_province: province,
                            crd31_postalcode: postal,
                            crd31_phone: phone,
                            crd31_eventid: this.readableEventId,
                            crd31_attendeepasses: JSON.stringify(this.attendees),
                        };
                        this.dynamicsService.saveBillMeDetails(data).subscribe(
                            (newOrderResponse: any) => {
                                console.log(
                                    'New Bill Me Details response:',
                                    newOrderResponse
                                );
                            },
                            (error) =>
                                this.handleError(
                                    new LocalizableError(
                                        'Failed to fetch token from Microsoft API.',
                                        'TokenFetchFailed'
                                    )
                                )
                        );

                        this.router.navigate(['../confirmation'], {
                            relativeTo: this.route,
                            queryParams: {
                                id: this.readableEventId,
                            },
                        });
                    } else {
                        this.handleError(
                            new LocalizableError(
                                registrationResult.errorMessage,
                                TranslationKeys.RegistrationFailed
                            )
                        );
                    }
                },
                (error: LocalizableError) => {}
            )
            .add(() => {
                this.registrationInProgress = false;
            });
    }

    fetchPaymentProfile(
        token: string,
        cardHolderName: string
    ): Observable<any> {
        const headers = new HttpHeaders({
            Authorization: environment.bamboraToken,
            'Content-Type': 'application/json',
        });

        const body = {
            token: { name: cardHolderName, code: token },
        };

        return this.http
            .post('https://api.na.bambora.com/v1/profiles', body, { headers })
            .pipe(
                tap((response) =>
                    console.log('Payment Profile Response:', response)
                ),
                catchError((error) => {
                    console.error('Payment Profile Error:', error);
                    throw error;
                })
            );
    }

    //   makePayment(paymentProfile: any): Observable<any> {
    //     const paymentData = {
    //         amount: this.total * 1.05, //Tax Checkpoint
    //         payment_method: 'payment_profile',
    //         payment_profile: {
    //             customer_code: paymentProfile.customer_code,
    //             card_id: paymentProfile.card_id,
    //             complete: true
    //         }
    //     };

    makePayment(paymentProfile: any): Observable<any> {
        const totalCents = Math.round(this.total * 100); // Convert total to cents
        const taxRate = 1.05; // Tax rate as a multiplier
        const amountCents = Math.round(totalCents * taxRate); // Calculate amount in cents and round

        const paymentData = {
            amount: (amountCents / 100).toFixed(2), // Convert back to dollars and format to 2 decimal places
            payment_method: 'payment_profile',
            payment_profile: {
                customer_code: paymentProfile.customer_code,
                card_id: paymentProfile.card_id,
                complete: true,
            },
        };

        const headers = new HttpHeaders({
            Authorization: environment.bamboraToken,
            'Content-Type': 'application/json',
        });

        return this.http
            .post('https://api.na.bambora.com/v1/payments/', paymentData, {
                headers,
            })
            .pipe(
                tap((response) => console.log('Payment Response:', response)),
                catchError((error) => {
                    console.error('Payment Error:', error);
                    throw error;
                })
            );
    }

    private handleError(error: LocalizableError) {
        this.error = error;
        this.registrationInProgress = false;
    }
}
