import { Injectable } from '@angular/core';

@Injectable()
export class RoleService {
    constructor() {}

    public hasMemberRole(userRole): boolean {
        if (userRole.includes('mta_member') || userRole.includes('rpm_member')) {
            return true;
        }

        return false;
    }
}
