<!-- <div tabindex="0" class="checkout mt-5">
    <div class="card col-md-10 offset-md-1 col-xl-8 offset-xl-2">
        <div class="row">
            <div class="card-header">
                <h2>
                    <span [appTranslate]="'ThankYouForRegistering'" aria-live="polite" role="alert" aria-label="Thank you for registering for the event!">Thank you for registering for the event!</span></h2>
            </div>

            <div class="card-body">

            </div>
        </div>
    </div>
</div>
 -->

 <div tabindex="0" class="checkout mt-5">
    <div class="card col-md-10 offset-md-1 col-xl-8 offset-xl-2">
        <div class="row">
            <div class="card-header">
                <h2>
                    <span [appTranslate]="'ThankYouForRegistering'" aria-live="polite" role="alert" aria-label="Thank you for registering for the event!">Thank you for registering for the event!</span>
                </h2>
            </div>
            <div class="card-body">
                <a href="https://trucking.mb.ca" class="link-button">Return to the MTA Site</a>
            </div>
        </div>
    </div>
</div>

<style>
    .link-button {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        color: white;
        background-color: #007BFF;
        border: none;
        border-radius: 5px;
        text-decoration: none;
        text-align: center;
        margin-top: 20px;
    }

    .link-button:hover {
        background-color: #0056b3;
    }
</style>