import { Injectable } from '@angular/core';

@Injectable()
export class PassService {
    constructor() {}

    public getLowestPassPrice(pass, userRole): number {
        if (userRole === undefined) {
            return pass?.price;
        }

        // Initialize price options array with base price at the start.
        const priceOptions = [pass.price];

        if (userRole.includes('mta_member')) {
            priceOptions.push(pass?.customFields?.new_mtaprice?.Value);
        }

        if (userRole.includes('rpm_member')) {
            priceOptions.push(pass?.customFields?.new_rpmprice?.Value);
        }

        const lowestPassPrice = Math.min(...priceOptions);
        return lowestPassPrice;
    }
}
